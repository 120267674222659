<template>
 <b-container fluid>
               <b-row>
                  <b-col sm="12">
                     <iq-card>
                        <template v-slot:headerTitle>
                              <h4 class="card-title">Add Books</h4>
                        </template>
                        <template v-slot:body>
                           <form @submit.prevent="simpanBuku">
                              <div class="form-group">
                                 <label>Judul Buku:</label>
                                 <input v-model="dataBuku.judul_buku" type="text" class="form-control">
                              </div>
                              <div class="form-group">
                                 <label>Penulis:</label>
                                 <input v-model="dataBuku.penulis" type="text" class="form-control">
                              </div>
                              <div class="form-group">
                                 <label>Tahun:</label>
                                 <input v-model="dataBuku.tahun" type="number" class="form-control">
                              </div>
                              <div class="form-group">
                                 <label>Kata Kunci:</label>
                                 <b-form-tags input-id="tags-basic" placeholder="kata kunci" v-model="dataBuku.kata_kunci"></b-form-tags>
                              </div>
                              <div class="form-group">
                                 <label>Gambar Cover:</label>
                                 <div class="custom-file">
                                    <b-form-file
                                       v-model="dataBuku.file_cover"
                                       placeholder="Choose a file or drop it here..."
                                       drop-placeholder="Drop file here..."
                                       accept=".jpg, .png, .gif"
                                    ></b-form-file>
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label>Buku PDF:</label>
                                 <div class="custom-file">
                                    <b-form-file
                                       v-model="dataBuku.file_buku"
                                       placeholder="Choose a file or drop it here..."
                                       drop-placeholder="Drop file here..."
                                       accept=".pdf"
                                    ></b-form-file>
                                 </div>
                              </div>
                              <div class="form-group">
                                 <label>Sinopsis:</label>
                                 <textarea v-model="dataBuku.sinopsis" class="form-control" rows="3"></textarea>
                              </div>
                              <!-- <router-link to="/admin/books"> -->
                              <button @click="simpanBuku()" type="submit" class="btn btn-primary">Submit</button>
                              <!-- </router-link> -->
                              <button type="reset" class="btn btn-danger">Reset</button>
                           </form>
                        </template>
                     </iq-card>
                  </b-col>
               </b-row>
 </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'addbook',
  mounted () {
    core.index()
  },
  computed: {
    ...mapGetters({
      lang: 'Setting/langState'
    })
  },
  watch: {
  },
  methods: {
   simpanBuku () {
      let formData = new FormData();
                formData.append('judul_buku', this.dataBuku.judul_buku);
                formData.append('tahun', this.dataBuku.tahun);
                formData.append('penulis', this.dataBuku.penulis);
                formData.append('sinopsis', this.dataBuku.sinopsis);
                formData.append('kata_kunci', this.dataBuku.kata_kunci);
                formData.append('file_buku', this.dataBuku.file_buku);
                formData.append('file_cover', this.dataBuku.file_cover);
          axios
            .post(this.baseapi + 'buku', formData, {
         headers: {
               // Authorization: `Bearer ` + this.user.accessToken
               }
         }
         )
         .then((res) => {
            this.$router.push("/admin/books");
            return res;
         })
         .catch((err) => {
         console.log(err)
         return err;
      });
   }
  },
  data () {
    return {
      dataBuku: [],
      baseapi: localStorage.getItem("baseapi")
    }
  }
}
</script>